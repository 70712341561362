<!-- eslint-disable -->
<template>
  <section
    style="z-index: 90;top: -60px;"
    class="has_eae_slider elementor-section elementor-top-section elementor-element elementor-element-95a6560 elementor-section-full_width elementor-section-height-min-height elementor-section-content-middle elementor-section-stretched elementor-section-height-default elementor-section-items-middle"
    data-particle_enable="false"
    data-particle-mobile-disabled="false"
    data-id="95a6560"
    data-element_type="section"
    data-settings='{"stretch_section":"section-stretched"}'
  >
    <div class="elementor-background-overlay"></div>
    <div class="elementor-container elementor-column-gap-no">
      <div
        class="has_eae_slider elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-f633e87"
        data-id="f633e87"
        data-element_type="column"
      >
        <div class="elementor-widget-wrap elementor-element-populated">
          <h3 v-show="error" style="color: red">
            Sorry Somthing Wrong in your request
          </h3>
          <section
            v-show="!error"
            class="has_eae_slider elementor-section elementor-inner-section elementor-element elementor-element-ad6735d elementor-section-full_width elementor-section-content-top elementor-section-height-default elementor-section-height-default"
            data-particle_enable="false"
            data-particle-mobile-disabled="false"
            data-id="ad6735d"
            data-element_type="section"
          >
            <div class="elementor-container elementor-column-gap-no">
              <div
                class="has_eae_slider elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-421b9ef"
                data-id="421b9ef"
                data-element_type="column"
              >
                <div class="elementor-widget-wrap elementor-element-populated">
                  <div
                    class="elementor-element elementor-element-4be0c2e elementor-widget elementor-widget-image"
                    data-id="4be0c2e"
                    data-element_type="widget"
                    data-widget_type="image.default"
                  >
                    <div class="elementor-widget-container">
                      <img
                        width="518"
                        height="268"
                        :src="hotelData.hotelLogo"
                        class="attachment-large size-large"
                        alt=""
                        loading="lazy"
                        sizes="(max-width: 518px) 100vw, 518px"
                      />
                    </div>
                  </div>
                  <div
                    class="elementor-element elementor-element-f31c90d elementor-align-center elementor-widget__width-auto elementor-widget elementor-widget-bdt-advanced-button"
                    data-id="f31c90d"
                    data-element_type="widget"
                    data-widget_type="bdt-advanced-button.default"
                  >
                    <div class="elementor-widget-container">
                      <div class="bdt-ep-button-wrapper">
                        <router-link
                          :to="`book`"
                          class="bdt-ep-button bdt-ep-button-effect-a bdt-ep-button-size-xl elementor-animation-push"
                        >
                          <div
                            class="bdt-ep-button-content-wrapper bdt-flex bdt-flex-middle bdt-flex-center"
                            data-text="Book Now"
                          >
                            <div class="bdt-ep-button-text">
                              <span class="avdbtn-text">{{lang.home.bookNow}}</span>
                            </div>
                          </div>
                        </router-link>
                      </div>
                    </div>
                  </div>
                  <div
                    class="elementor-element elementor-element-45d0cb9 elementor-align-center elementor-widget__width-auto elementor-widget elementor-widget-bdt-advanced-button"
                    data-id="45d0cb9"
                    data-element_type="widget"
                    data-widget_type="bdt-advanced-button.default"
                  >
                    <div class="elementor-widget-container">
                      <div class="bdt-ep-button-wrapper">
                        <router-link
                          :to="'rate-us'"
                          class="bdt-ep-button bdt-ep-button-effect-a bdt-ep-button-size-xl elementor-animation-push"
                        >
                          <div
                            class="bdt-ep-button-content-wrapper bdt-flex bdt-flex-middle bdt-flex-center"
                            data-text="Review Us"
                          >
                            <div class="bdt-ep-button-text">
                              <span class="avdbtn-text">{{lang.home.reviewUs}}</span>
                            </div>
                          </div>
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: "Home",
  data() {
      //  

    return {
      hotelId: 1,
      hotelData: {
        hotelLogo: "",
        hotelMainColor: "",
        hotelSecondColor: "",
      },
      error: false,
      lang : ""
    };
  },
  created() {
    localStorage.setItem("hotel", 1);
    this.setLang();
	this.getHotelData();
	document.querySelector('.slider-section').style.display="block";
  },
  methods: {
    getHotelData() {
      
      this.axios
        .get(
          process.env.VUE_APP_API_BASE_URL +
            "api/hotel-data?api_key=" +
            process.env.VUE_APP_API_KEY +
            "&lang=" +
            process.env.VUE_APP_LANG +
            "&hotel_id=" +
            1
        )
        .then((res) => {
          
          console.log(res.data.data);
          this.hotelData.hotelLogo =
            process.env.VUE_APP_API_BASE_URL + res.data.data.logo;
          this.hotelData.hotelMainColor = res.data.data.main_color;
          this.hotelData.hotelSecondColor = res.data.data.second_color;
          this.$emit("hotel-data", this.hotelData);
          localStorage.setItem(
            "hotelLogo",
            process.env.VUE_APP_API_BASE_URL + res.data.data.logo
          );
          localStorage.setItem("currency",res.data.data.currency)

        })
        .catch((error) => {
          console.log(error);
          this.error = true;
        });
    },
    setLang() {
      this.axios
        .get(
          process.env.VUE_APP_API_BASE_URL +
            "api/frontend-keywords?api_key=" +
            process.env.VUE_APP_API_KEY +
            "&lang=" +
            localStorage.getItem("localization")
        )
        .then(
          (res) => {
            console.log(res)
            this.lang = res.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  computed: {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
<!-- eslint-enable -->
